import axios from "axios";
import i18n from 'i18next';

export default class Api {
  constructor() {
    this.api_url = process.env.REACT_APP_api_endpoint;
    // old URL
    this.api_old_url = process.env.REACT_APP_old_api_endpoint;
    this.api_token = null;
    this.accept_language = i18n.language;
  }
  init = () => {
    let headers = {
      Accept: "application/json",
      "Accept-Language": this.accept_language,
    };
    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }
    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });
    return this.client;
  };

  // init old url
  init_old = () => {
    let headers = {
      Accept: "application/json",
      "Accept-Language": this.accept_language,
    };
    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }
    this.client = axios.create({
      baseURL: this.api_old_url,
      timeout: 31000,
      headers: headers,
    });
    return this.client;
  };

  checkDriverActivation = (code) => {
    return this.init_old().get(`/activation/${code}`);
  };

  confirmActivation = (code) => {
    return this.init_old().post(`/activation/${code}`);
  };

  getReceipt = ({ queryKey }) => {
    const [id] = queryKey;
    return this.init().get(`/transactions/${id}/receipt`);
  };
}
