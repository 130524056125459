import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';

export const EN_HK = "en-hk";
export const ZH_HK = "zh-hk";
const POSSIBLE_LOCALES = [EN_HK, ZH_HK];

export function useLanguage() {
    const search = useLocation().search;
    const queryLang = new URLSearchParams(search).get('lang')?.toLowerCase();
    const initialLang = POSSIBLE_LOCALES.includes(queryLang) ? queryLang : ZH_HK;
    const [lang, setLang] = useState(initialLang);
    const [cookies, setCookie] = useCookies(['lang']);
    
    useEffect(() => {
        if (lang !== cookies.lang){
            setCookie('lang' ,lang , {path :'/'} )
        }
    } , [lang, cookies.lang]);

    return {
        lang,
        setLang
    }
}