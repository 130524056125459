import { Button, Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { EN_HK, ZH_HK, useLanguage } from "../hooks/use-language";
import Api from "../utils/api";
import { bundle } from "../utils/bundle";
import "./verify.scss";

const URL = {
  "zh-hk": process.env.REACT_APP_driver_terms_of_service_zh_hk,
  "en-hk": process.env.REACT_APP_driver_terms_of_service_en_hk,
};

const Stage = {
  Loading: 0,
  NotFound: 1,
  WaitForActive: 2,
  AlreadyActive: 3,
  SystemError: 4,
};

const SystemError = () => {
  return (
    <div className="container verify-container">
      <div className="content">
        <h2>System error</h2>
      </div>
    </div>
  );
};

const Loading = () => {
  return (
    <div className="container verify-container">
      <div className="content">
        <h2>Loading...</h2>
      </div>
    </div>
  );
};

const Notfound = () => {
  return (
    <div className="container verify-container">
      <div className="content">
        <h2>Activation code not found</h2>
      </div>
    </div>
  );
};

const AlreadyActive = () => {
  return (
    <div className="container verify-container">
      <div className="content">
        <h2>Already Active</h2>
      </div>
    </div>
  );
};

const WaifForActive = (props) => {
  return (
<div className="container verify-container">
  <div className="sticky-header">
  <div>
          <Link onClick={() => props.setLang(EN_HK)}>ENG</Link>
        </div>
        <div>
          <Link onClick={() => props.setLang(ZH_HK)}>中文</Link>
        </div>
  </div>
  <iframe title="t_and_c_content" src={URL[props.lang]}></iframe>
  <div className="content">
  <div className="agree">
          <Checkbox checked={props.acceptTandC} onChange={props.onChange} />
          {bundle[props.lang].i_agree_with_the_term}
        </div>
        <div className="accept-button">
          <Button
            disabled={!props.acceptTandC}
            variant="outlined"
            onClick={props.onClick}
          >
            {bundle[props.lang].agree_btn}
          </Button>
        </div>
  </div>
</div>
  );
};

const ActivateVerify = () => {
  const params = useParams();
  const { lang, setLang } = useLanguage();

  const api = new Api();
  const [driverStage, setDriverStage] = useState(Stage.Loading);
  const [acceptTandC, setAcceptTandC] = useState(false);

  useEffect(() => {
    api
      .checkDriverActivation(params.id)
      .then((resp) => {
        const data = resp.data;

        if (data.data) {
          setDriverStage(Stage.WaitForActive);
        } else {
          setDriverStage(Stage.AlreadyActive);
        }
      })
      .catch((err) => {
        console.log("err", err);
        const resp = err.response;
        if (resp.status === 404) {
          setDriverStage(Stage.NotFound);
        } else {
          setDriverStage(Stage.SystemError);
        }
      });
  }, []);

  const handleCheckBox = (e) => {
    setAcceptTandC(e.target.checked);
  };

  const confirmButton = () => {
    setDriverStage(Stage.Loading);
    api
      .confirmActivation(params.id)
      .then((resp) => {
        // console.log(resp.data)
        const data = resp.data;
        // console.log(data)
        if (data.data) {
          setDriverStage(Stage.AlreadyActive);
        } else {
          setDriverStage(Stage.WaitForActive);
        }
      })
      .catch((err) => {
        console.log("err", err.response);
        setDriverStage(Stage.SystemError);
      });
  };

  return (
    <main>
      {(driverStage === Stage.Loading && <Loading />) ||
        (driverStage === Stage.NotFound && <Notfound />) ||
        (driverStage === Stage.WaitForActive && (
          <WaifForActive
            onChange={handleCheckBox}
            onClick={confirmButton}
            lang={lang}
            setLang={setLang}
            acceptTandC={acceptTandC}
          />
        )) ||
        (driverStage === Stage.AlreadyActive && <AlreadyActive />) ||
        (driverStage === Stage.SystemError && <SystemError />)}
    </main>
  );
};

WaifForActive.propTypes = {
  setLang: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  acceptTandC: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default ActivateVerify;
