import { isNil } from "lodash";
import moment from "moment";
export const conventDateTime = (datetime) => {
  let ret = "--";
  if (!isNil(datetime)) {
    let m = moment(datetime);
    ret = m.format("DD/MM/YYYY HH:mm");
  }
  return ret;
};

export const conventDate = (datetime) => {
  let ret = "--";
  if (!isNil(datetime)) {
    let m = moment(datetime);
    ret = m.format("DD/MM/YYYY");
  }
  return ret;
};

export const conventTime = (datetime) => {
  let ret = "--";
  if (!isNil(datetime)) {
    let m = moment(datetime);
    ret = m.format("HH:mm");
  }
  return ret;
};

export const formatCurrency = (amount) => {
  const formatter = new Intl.NumberFormat('en-HK', {
    style: 'currency',
    currency: 'HKD',
    currencyDisplay: 'symbol',
  });
  return formatter.format(amount);
};