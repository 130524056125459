import { EN_HK, ZH_HK } from "../hooks/use-language";

export const bundle = {
  [ZH_HK]: {
    i_agree_with_the_term: "本人已閱讀及同意遵守上述條款及細則",
    agree_btn: "本人同意",
  },
  [EN_HK]: {
    i_agree_with_the_term: " I agree with the Terms and Conditions",
    agree_btn: "I AGREE",
  },
};
