import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import zh from './locales/zh.json';

// Function to get URL query parameter
const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };
  
// Function to detect and return the appropriate language
const detectLanguage = () => {
    const supportedLanguages = ['en-US', 'zh-HK']; // Define supported specific languages
  
    // Check for ?lang parameter in the URL
    const langParam = getQueryParam('lang');
  
    // If there's a valid ?lang param, use it
    if (langParam && supportedLanguages.includes(langParam)) {
      return langParam;
    }
  
    // Fall back to browser language if no valid ?lang parameter is present
    const browserLang = navigator.language || navigator.userLanguage; // Get browser language
  
    if (supportedLanguages.includes(browserLang)) {
      return browserLang;
    }
  
    // If browserLang is zh-HK, zh-TW, or any Chinese variant, map to a common zh.json
    if (browserLang.startsWith('zh')) {
        return 'zh-HK';
    }
  
    return 'en-US'; // Default to 'en' if no valid language is found
  };

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      zh: {
        translation: zh,
      },
    },
    lng: detectLanguage(), // language to use
    fallbackLng: 'en', // fallback language if translation is missing
    interpolation: {
      escapeValue: false, // react already safe from XSS
    },
  });

export default i18n;