import { default as React } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import { conventDate, conventTime } from "../utils/util.js";
import "./receipt.scss";

import { useTranslation } from 'react-i18next';
import '../i18n'; // Import the i18n configuration

import {
  Alert,
  AlertTitle,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import GoogleMapReact from "google-map-react";
import { sum } from "lodash";
import Api from "../utils/api";

import { formatCurrency } from "../utils/util.js";

const PointComponent = ({ point }) => (
  <div className={point === "S" ? "pointstart" : "pointend"}>{point}</div>
);

const Receipt = () => {
  const { t } = useTranslation();
  const params = useParams();
  const api = new Api();
  const { data, status } = useQuery(params.id, api.getReceipt, {
    select: ({ data }) => data,
    refetchOnWindowFocus: false,
  });

  const defaultProps = {
    center: {
      lat: parseFloat(process.env.REACT_APP_receiptDefaultLatitude),
      lng: parseFloat(process.env.REACT_APP_receiptDefaultLongitude),
    },
    zoom: parseInt(process.env.REACT_APP_receiptDefaultZoom, 10),
    api_key: process.env.REACT_APP_apiKey,
  };

  return (
    <main>
      <div className="container receipt-container">
        {status === "loading" && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {status === "error" && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Receipt <strong>{params.id}</strong> not found
          </Alert>
        )}
        {status === "success" && (
          <>
            <div className="receipt-header">
              <div className="logo"></div>
              <div className="text">{t('header.thanks')}</div>
            </div>
            <div className="receipt-body">
              <div className="info">
                <div className="price">
                  <div className="title">{t('totalPaid')}
                  {data.discountAmount && data.discountAmount !== 0 ? (
                    <span className="chip">{t('discountAppliedChip')}</span>
                  ) : null}
                  </div>
                  <div className="price-amount">
                    {formatCurrency(sum([data.total, data.adjustment]) || 0)}
                  </div>
                </div>
                <div className="blocks">
                  <div className="column">
                    <div className="info-block">
                      <dt>{t('date')}</dt>
                      <dd>{conventDate(data.tripStart)}</dd>
                    </div>
                    <div className="info-block">
                      <dt>{t('txId')}</dt>
                      <dd>{data.id}</dd>
                    </div>
                  </div>
                  <div className="column">
                    <div className="info-block">
                      <dt>{t('startTime')}</dt>
                      <dd>{conventTime(data.tripStart)}</dd>
                    </div>

                    <div className="info-block">
                      <dt>{t('endTime')}</dt>
                      <dd>{conventTime(data.tripEnd)}</dd>
                    </div>

                    <div className="info-block car-plate">
                      <dd>
                        <span>{data.licensePlate || ""}</span>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>

              <div className="map">
                <div className="column">
                  <div className="title">{t('tripDetailsHeader')}</div>

                  <dl className="route-info">
                    <div className="left">
                      <dd className="start">{t('startLocation')}</dd>
                      <dt>{data.locationStartAddress}</dt>
                    </div>
                    <div className="right">
                      <dd className="end">{t('endLocation')}</dd>
                      <dt>{data.locationEndAddress}</dt>
                    </div>
                  </dl>
                </div>
                <div className="column map-container">
                  {data.locationStart && data.locationEnd ? (
                    <GoogleMapReact
                      defaultCenter={{
                        lat: data.locationStart._latitude,
                        lng: data.locationStart._longitude,
                      }}
                      defaultZoom={defaultProps.zoom}
                      bootstrapURLKeys={{ key: process.env.REACT_APP_apiKey }}
                    >
                      <PointComponent
                        lat={data.locationStart._latitude}
                        lng={data.locationStart._longitude}
                        point={"S"}
                      />

                      <PointComponent
                        lat={data.locationEnd._latitude}
                        lng={data.locationEnd._longitude}
                        point={"E"}
                      />
                    </GoogleMapReact>
                  ) : (
                    "Map loading"
                  )}
                </div>
              </div>
              <div className="payment">
                <div className="title">{t('paymentDetailsHeader')}</div>
                <div className="blocks flex-column">
                  <div className="column">
                    <div className="info-block credit-card">
                      <dt>
                    {(data.paymentCardInformation?.cardType === "VISA" ||
                          data.paymentCardInformation?.cardType === "MASTER" ||
                          data.paymentCardInformation?.cardType === "UNIONPAY" ||
                          data.paymentCardInformation?.cardType === "ALIPAY" ||
                          data.paymentCardInformation?.cardType === "OCTOPUS" ||
                          data.paymentCardInformation?.cardType === "WECHAT"
                        ) && (
                          <img
                            className="payment-logo"
                            src={
                              "/icons8-" +
                              data.paymentCardInformation.cardType.toLowerCase() +
                              ".svg"
                            }
                            alt="Dash"
                            height={48}
                          />
                        )}
                      { data.paymentCardInformation?.maskedPan.startsWith("undefined") 
                          ?"-": data.paymentCardInformation?.maskedPan || "-"
                      }</dt>
                    </div>
                  </div>

                  <div className="column">
                    <div className="info-block-horizontal">
                      <dt>{t('fare')}</dt>
                      <dd>{formatCurrency(data.fare || 0)}</dd>
                    </div>
                  </div>
                  <div className="column">
                    <div className="info-block-horizontal">
                      <dt>{t('extras')}</dt>
                      <dd>{formatCurrency(data.extra || 0)}</dd>
                    </div>
                  </div>
                  {data.dashTips && data.dashTips !== 0 ? (
                  <div className="column">
                    <div className="info-block-horizontal">
                      <dt>{t('tips')}</dt>
                      <dd>{formatCurrency(data.dashTips || 0)}</dd>
                    </div>
                  </div>
                  ) : null}
                  <div className="column">
                    <div className="info-block-horizontal">
                      <dt>{t('platformFee')}</dt>
                      <dd>{formatCurrency(data.dashFee || 0)}</dd>
                    </div>
                  </div>
                  {data.discountAmount && data.discountAmount !== 0 ? (
                  <div className="column">
                    <div className="info-block-horizontal">
                      <dt>{t('discount')}</dt>
                      <dd>{formatCurrency(data.discountAmount)}</dd>
                    </div>
                  </div>
                  ) : null}
                  {data.adjustment && data.adjustment  !== 0 ? (
                  <div className="column">
                    <div className="info-block-horizontal">
                      <dt>{t('adjustment')}</dt>
                      <dd>{formatCurrency(data.adjustment)}</dd>
                    </div>
                  </div>
                  ) : null}
                  <div className="column">
                    <div className="info-block-horizontal total">
                      <dt>{t('total')}</dt>
                      <dd>{formatCurrency(sum([data.total, data.adjustment]))}</dd>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="footer">
                <div className="terms">
                  <a
                    href="https://www.d-ash.com/user-terms-of-service"
                    target="_blank" rel="noreferrer"
                  >
                    <div className="button">{t('footer.tnc')}</div>
                  </a>
                </div>
                <div className="center">
                {t('footer.contactUs')}@ <br></br>+852 6433 4883<br></br>{t('footer.byPhoneOrbyWhatsApp')}<br></br>
                  <br></br>
                  <span className="rights">
                    Vis Mobility Ltd. {t('footer.allRightsReserved')}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </main>
  );
};


PointComponent.propTypes ={
  point:PropTypes.string.isRequired
}
export default Receipt;
