import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ActivateVerify from "./components/verify";
import "./App.css";
import Receipt from "./components/receipt";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<Receipt />} path="/receipt/:id" />
          <Route element={<ActivateVerify />} path="/activation/:id" />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
